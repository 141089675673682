'use client';

import cn from 'classnames';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import Style from './LinkCustom.module.scss';

type CustomProps = {
  linkText?: string;
  className?: string;
  extraClass?: string;
  moduleClass?: string;
  children?: React.ReactNode;
  role?: string;
};

type Props = CustomProps &
  LinkProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

const LinkCustom = ({
  linkText,
  className = '',
  extraClass = '',
  moduleClass = '',
  role = 'link',
  children,
  href = '',
  target = '_self',
  onClick,
  ...rest
}: Props) => {
  return (
    <Link
      role={role}
      className={cn(
        Style.btn,
        {
          [Style[className] as string]: className,
          [Style[extraClass] as string]: extraClass,
        },
        moduleClass,
        extraClass,
      )}
      href={href || ''}
      target={target}
      onClick={onClick}
      {...rest}
    >
      <span>{children ? children : linkText}</span>
    </Link>
  );
};

export default LinkCustom;
